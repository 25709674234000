export default {
  productInstanceTableHeaders: [
    { translationKey: 'productInstanceId', value: 'productInstanceId' },
    { translationKey: 'name', value: 'name' },
    { translationKey: 'actions', value: 'actions' },
  ],
  subscriptionTableHeaders: [
    { translationKey: 'subscriptionId', value: 'subscriptionId' },
    { translationKey: 'productInstanceId', value: 'productInstanceId' },
    { translationKey: 'channelName', value: 'channel' },
    { translationKey: 'actions', value: 'actions' },
  ],
  productSubscriptionTableHeaders: [
    { translationKey: 'subscriptionId', value: 'subscriptionId' },
    { translationKey: 'actions', value: 'actions' },
  ],
  productInstances: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
  },
  subscriptions: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
  },
  currentVDRConfig: undefined,
  currentProductSubscriptions: [],
  supportedPayInTaxes: [],
  supportedPayOutTaxes: [],
  paymentStrategies: [
    'PER_BET',
    'PER_TICKET',
  ],
};
