export default {
  productInstanceTableHeaders: [
    { translationKey: 'productInstanceId', value: 'uuid' },
    { translationKey: 'name', value: 'productInstanceName' },
    { translationKey: 'rtpConfiguration', value: 'rtpConfigurationName' },
    { translationKey: 'startDuration', value: 'startDuration' },
    { translationKey: 'countdownDuration', value: 'countdownDuration' },
    { translationKey: 'actions', value: 'actions' },
  ],
  subscriptionTableHeaders: [
    { translationKey: 'subscriptionId', value: 'uuid' },
    { translationKey: 'productInstanceId', value: 'productInstanceId' },
    { translationKey: 'tenantName', value: 'tenantName' },
    { translationKey: 'channelName', value: 'channel' },
    { translationKey: 'actions', value: 'actions' },
  ],
  productSubscriptionTableHeaders: [
    { translationKey: 'subscriptionId', value: 'uuid' },
    { translationKey: 'tenantName', value: 'tenantName' },
    { translationKey: 'actions', value: 'actions' },
  ],
  productInstances: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
  },
  subscriptions: {
    data: [],
    pagination: {
      page: 1,
      size: 10,
      total: 0,
    },
  },
  currentCrashCashConfig: undefined,
  currentProductSubscriptions: [],
  supportedRTPConfigurations: [],
  supportedPayInTaxes: [],
  supportedPayOutTaxes: [],
  paymentStrategies: [
    'PER_BET',
    'PER_TICKET',
  ],
};
