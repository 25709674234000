import { assign } from 'lodash';
import vuetify from '../../../plugins/vuetify';

export default {
  productInstances: (state) => state.productInstances.data,
  productInstancesPagination: (state) => state.productInstances.pagination,
  productInstanceTableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.productInstanceTableHeaders.map(convert);
  },
  subscriptions: (state) => state.subscriptions.data,
  subscriptionsPagination: (state) => state.subscriptions.pagination,
  subscriptionTableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.subscriptionTableHeaders.map(convert);
  },
  productSubscriptionTableHeaders: (state) => {
    const convert = (tableHeader) => {
      assign(tableHeader, {
        text: vuetify.framework.lang.t(`$vuetify.${tableHeader.translationKey}`),
      });

      return tableHeader;
    };
    return state.productSubscriptionTableHeaders.map(convert);
  },

  currentVDRConfig: (state) => state.currentVDRConfig,
  currentProductSubscriptions: (state) => state.currentProductSubscriptions,
  supportedPayInTaxes: (state) => state.supportedPayInTaxes,
  supportedPayOutTaxes: (state) => state.supportedPayOutTaxes,
  paymentStrategies: (state) => state.paymentStrategies,
};
